export const bootStrapGrid = { sm: 768, md: 992, lg: 1200 };

export const allPatentTypes = [
  "PatentDossier",
  "PatentDossierUtilityModel",
  "PatentEurope",
  "Spc",
];

export const allPatentStatuses = [
  "Application_refused",
  "Application_dismissed",
  "Expired",
  "Renounced",
  "Annulled",
  "Opposition",
  "UMopposition",
  "Appeal",
  "Patent_revoked",
  "Application_withdrawn",
  "Application_filed",
  "Valid",
  "Appeal",
  "Limited",
  "Approved",
  "Basic_patent_has_lapsed",
  "EP_660_notvalidated",
  "EP_630_oppo_B2",
  "EP_620_grant_B1",
  "EP_632_limit_B3",
  "EP_700_applrefused",
  "EP_800_application_dismissed",
  "EP_600_imported",
  "EP_640_revoked",
  "EP_631_oppo_T4",
  "EP_635_limit_T6",
  "EP_650_appwthdr",
  "EP_621_valid_T3",
  "EP_625_opposition",
  "EP_610_provisional_T1",
  "Opposition_filed",
  "Opposition_closed",
  "Opposition_dismissed",
  "Opposition_withdrawn",
  "Appeal_filed",
  "Appeal_closed",
  "Appeal_dismissed",
  "Appeal_withdrawn",
  "Revival_requested",
  "application_filed",
  "application_withdrawn",
  "application_dismissed",
  "application_refused",
  "application_deemed_not_filed",
  "annulled",
  "request_suspended",
  "valid",
  "limited",
  "patent_revoked",
  "continued_PCT_canceled",
  "renounced",
  "lapsed",
  "expired",
  "completed",
  "appeal",
  "kho_appeal",
  "revival_requested",
  "nato",
  "opposition",
  "application_filed",
  "application_withdrawn",
  "application_dismissed",
  "application_refused",
  "application_deemed_not_filed",
  "annulled",
  "request_suspended",
  "valid",
  "limited",
  "continued_PCT_canceled",
  "renounced",
  "lapsed",
  "expired",
  "completed",
  "appeal",
  "kho_appeal",
  "revival_requested",
  "um_opposition",
  "application_filed",
  "application_withdrawn",
  "application_dismissed",
  "application_refused",
  "valid",
  "approved",
  "lapsed",
  "spc_expired",
  "spc_discontinued",
  "renounced",
  "application_annulled",
  "basic_patent_has_lapsed",
  "revival_requested",
  "appeal",
  "filed",
  "closed",
  "dismissed",
  "withdrawn",
  "application_lapsed_due_annual_subscription",
  "patentutility_valid",
  "patentutility_annulled",
  "request_suspended",
  "patent_restricted",
  "patent_limited",
  "extended_international_application_is_canceled",
  "patent_corrected",
  "patent_changes",
  "application_refused",
  "application_not_deemed_to_been_made",
  "completed",
  "application_withdrawn",
  "ro_filed",
  "ro_finished",
  "isa_filed",
  "isa_finished",
  "ipea_filed",
  "ipea_finished",
  "sisa_filed",
  "sisa_finished",
  "ep_removed",
];

export const registeredPatentStatuses = [
  "Application_filed",
  "Valid",
  "Appeal",
  "Limited",
  "Approved",
  "EP_630_oppo_B2",
  "EP_620_grant_B1",
  "EP_632_limit_B3",
  "EP_600_imported",
  "EP_631_oppo_T4",
  "EP_635_limit_T6",
  "EP_621_valid_T3",
  "EP_625_opposition",
  "EP_610_provisional_T1",
  "Opposition_filed",
  "Appeal_filed",
  "Opposition",
  "UMopposition",
  //"nato",
  "kho_appeal",
  "filed",
  "patentutility_valid",
  "patent_restricted",
  "patent_limited",
  "patent_corrected",
  "patent_changes",
  "ep_appeal",
  "ep_registered",
  "ep_provisional_T1",
  "ep_grant_B1",
  "ep_valid_T3",
  "ep_opposition",
  "ep_oppo_B2",
  "ep_oppo_T4",
  "ep_limit_B3",
  "ep_limit_T6",
];

export const nonRegisteredPatentStatuses = [
  "Application_refused",
  "Application_dismissed",
  "Expired",
  "Renounced",
  "Annulled",
  "Patent_revoked",
  "Application_withdrawn",
  "Lapsed",
  "Basic_patent_has_lapsed",
  "EP_660_notvalidated",
  "EP_700_applrefused",
  "EP_800_application_dismissed",
  "EP_640_revoked",
  "EP_650_appwthdr",
  "Opposition_closed",
  "Opposition_dismissed",
  "Opposition_withdrawn",
  "Appeal_closed",
  "Appeal_dismissed",
  "Appeal_withdrawn",
  //"nato",
  "annulled",
  "continued_PCT_canceled",
  "expired",
  "renounced",
  "closed",
  "dismissed",
  "withdrawn",
  "application_lapsed_due_annual_subscription",
  "patentutility_annulled",
  "request_suspended",
  "extended_international_application_is_canceled",
  "application_refused",
  "completed",
  "application_withdrawn",
];

export const initialSearchParams = {
  patentTitle: "",
  applicationNumber: "",
  registrationNumber: "",
  priorityNumber: "",
  dossierStatus: allPatentStatuses,
  applicant: "",
  assignee: "",
  inventor: "",
  representative: "",
  applicationStartDate: "",
  applicationEndDate: "",
  filingStartDate: "",
  filingEndDate: "",
  oppositionPeriodStartStartDate: "",
  oppositionPeriodStartEndDate: "",
  grantStartDate: "",
  grantEndDate: "",
  publicationStartDate: "",
  publicationEndDate: "",
  priorityStartDate: "",
  priorityEndDate: "",
  ipcClassification: "",
  cpcClassification: "",
  basicSearch: "",
  patentTypes: allPatentTypes,
  noAuthoAppm: "",
};

export default false;
