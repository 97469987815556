const validatePatentTitle = (item) => {
  const trimmed = item.trim();
  return trimmed.length < 1 || (trimmed.length > 2 && trimmed.length < 999);
};

const validateApplicationNumber = (item) => {
  const trimmed = item.trim();
  const regex = /^$|^(u|U|fi|FI|c|C|l|L|k|K|ep|EP|\d)+(\w|.){2,999}$/;
  return trimmed.match(regex);
};

const validateRegistrationNumber = (item) => {
  const trimmed = item.trim();
  const regex = /^$|^(ep|EP|\d)+(\w|.){0,999}$/;
  return trimmed.match(regex);
};
const validatePriorityNumber = (item) => {
  const trimmed = item.trim();
  const regex = /^$|^(ep|EP|u|U|fi|FI|\d)+(\w|.){2,999}$/;
  return trimmed.match(regex);
};

const validateNoAuthoAppm = (item) => {
  const trimmed = item.trim();
  const regex = /^$|^([a-zA-ZöäåÖÄÅ]{2})+(\/|\w|.){1,999}$/;
  return trimmed.match(regex);
}

const validateIpcClassification = (item) => {
  const trimmed = item.trim();
  const regex = /^$|^([a-zA-ZöäåÖÄÅ][\d][\d][a-zA-ZöäåÖÄÅ])+.{0,999}$/;
  return trimmed.match(regex);
};

const validateCpcClassification = (item) => {
  const trimmed = item.trim();
  const regex = /^$|^([a-zA-ZöäåÖÄÅ][\d][\d][a-zA-ZöäåÖÄÅ])+.{0,999}$/;
  return trimmed.match(regex);
};

const validateDates = (startDate, endDate) => {
  if(startDate === "" || endDate === "")
    return true;

  const start = new Date(startDate);
  const end = new Date(endDate);

  if (end.getTime() < start.getTime())
    return false;
  return true;
}

export const validateSearchForm = (fields) => {
  let errors = [];
  if (!validatePatentTitle(fields.patentTitle)) {
    errors.push("patentTitle");
  }
  if (!validateApplicationNumber(fields.applicationNumber)) {
    errors.push("applicationNumber");
  }
  if (!validateRegistrationNumber(fields.registrationNumber)) {
    errors.push("registrationNumber");
  }
  if (!validatePriorityNumber(fields.priorityNumber)) {
    errors.push("priorityNumber");
  }
  if (fields.applicant.length > 999) {
    errors.push("applicant");
  }
  if (fields.assignee.length > 999) {
    errors.push("assignee");
  }
  if (fields.inventor.length > 999) {
    errors.push("inventor");
  }
  if (fields.representative.length > 999) {
    errors.push("representative");
  }
  if (!validateIpcClassification(fields.ipcClassification)) {
    errors.push("ipcClassification");
  }
  if (!validateCpcClassification(fields.cpcClassification)) {
    errors.push("cpcClassification");
  }
  if(!validateDates(fields.applicationStartDate, fields.applicationEndDate)){
    errors.push("applicationDate");
  }
  if(!validateDates(fields.filingStartDate, fields.filingEndDate)){
    errors.push("filingDate");
  }
  if(!validateDates(fields.oppositionPeriodStartStartDate, fields.oppositionPeriodStartEndDate)){
    errors.push("oppositonPeriodStartDate");
  }
  if(!validateDates(fields.grantStartDate, fields.grantEndDate)){
    errors.push("grantDate");
  }
  if(!validateDates(fields.priorityStartDate, fields.priorityEndDate)){
    errors.push("priorityDate");
  }
  if(!validateDates(fields.publicationStartDate, fields.publicationEndDate)){
    errors.push("publicationDate");
  }
  if(!validateNoAuthoAppm(fields.noAuthoAppm)){
    errors.push("noAuthoAppm");
  }

  return errors;
};
